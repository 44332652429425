<template>
  <div>
    <div id="pageEditMessage" class="mainContents">
      <UserNavSp :user="u" :page="'message'" />

      <UserTitle :user="u" />

      <UserNav :user="u" :page="'message'" />

      <div class="editMessageDetailArea">
        <div class="inner">
          <p class="profile" :class="m.from_user.profile_color">
            <a
              href="#"
            ><BaseUserImg :user="m.from_user" /><span>{{
              m.from_user.full_name
            }}</span></a><span class="written">{{ formatDate(m.created) }}</span>
          </p>
          <p class="text">
            {{ m.message }}
          </p>
        </div>
        <!-- /.editMessageDetailArea -->
      </div>

      <div v-if="canSendMessage" class="messageArea">
        <div class="inner">
          <h2>
            <span>{{ m.from_user.full_name }}</span>さんへのメッセージ
          </h2>
          <template v-if="!completed">
            <form>
              <div class="textareaFrame">
                <textarea
                  v-model="message.message"
                  placeholder="コメント入力…"
                  :class="{ 'text-danger': !validMessage }"
                />
              </div>
              <p class="button">
                <button
                  type="submit"
                  :disabled="formInvalid"
                  @click="send($event)"
                >
                  送信
                </button>
              </p>
            </form>
          </template>
          <template v-if="completed">
            送信しました
          </template>
          <div class="buttonArea">
            <p>
              <router-link class="message-link" :to="{ name: 'user-inbox' }">
                一覧に戻る
              </router-link>
            </p>
            <!-- /.buttonArea -->
          </div>
        </div>
        <!-- /.messageArea -->
      </div>
      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import { authComputed } from '@/store/helpers.js'
import { fieldNotNull } from '@/helpers/validator.js'
import NProgress from 'nprogress'
import { commonDate } from '@/helpers/date-format.js'
import store from '@/store/index'

export default {
  components: {
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: true
    },
    m: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isUploading: false,
      completed: false,
      message: {},
      isBlocked: true
    }
  },
  computed: {
    ...authComputed,
    canSendMessage: function () {
      if (this.isBlocked) {
        return false
      }

      return true
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validMessage) {
        return true
      }

      return false
    },
    validMessage: function () {
      return fieldNotNull(this.message.message)
    }
  },
  created: function () {
    this.message.user_id = this.m.from_user.id

    store.dispatch('user/isBlockedFrom', this.m.from_user.id).then(res => {
      this.isBlocked = res.isBlockedFrom.blocked
    })

    store.dispatch('user/messageViewed', this.m.id).then(() => {
      store.dispatch('getUnreadMessageCount')
    })
  },
  methods: {
    send (event) {
      event.preventDefault()
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('user/sendMessage', this.message)
        .then(res => {
          this.isUploading = false
          NProgress.done()
          if (res.data.errors) {
            this.errors = res.data.errors
          } else {
            this.completed = true
          }
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
        })
    },
    formatDate (date) {
      return commonDate(date, 'YYYY.MM.DD hh:mm:ss')
    }
  }
}
</script>
<style scoped></style>
